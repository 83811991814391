import { createEvent, createStore, combine } from 'effector';

const setFeatureFlags = createEvent();
const $featureFlags = createStore({}).on(setFeatureFlags, (s, v) => ({
  ...s,
  ...v,
}));

export const stores = {
  $featureFlags,
};

export const store = combine(stores);

export const actions = {
  setFeatureFlags,
};
