import {
  createEvent,
  createEffect,
  sample,
  createStore,
  combine,
} from 'effector';

import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

const setValue = createEvent();
const clearValue = createEvent();
const initRebateIds = createEvent();
const initBestWarrantyIds = createEvent();
const initCheapIds = createEvent();
const initAllWeatherIds = createEvent();
const initProductsByRecommendations = createEvent();

const $value = createStore('')
  .reset(clearValue)
  .on(setValue, (_, v) => v);

const $isEmpty = $value.map(v => v === '');
const $isDeals = $value.map(v => v === 'deals');
const $isBestWarranty = $value.map(v => v === 'bestWarranty');
const $isCheap = $value.map(v => v === 'cheap');
const $isAllWeather = $value.map(v => v === 'allWeather');
const $isRecommended = $value.map(v => v === 'recommended');

const $rebateIds = createStore([]).on(initRebateIds, (_, v) => compact(v));
const $bestWarrantyIds = createStore([]).on(initBestWarrantyIds, (_, v) =>
  compact(v),
);
const $cheapIds = createStore([]).on(initCheapIds, (_, v) => compact(v));
const $allWeatherIds = createStore([]).on(initAllWeatherIds, (_, v) =>
  compact(v),
);
const $productsByRecommendation = createStore([]).on(
  initProductsByRecommendations,
  (_, v) => v,
);
const $recommendationIds = $productsByRecommendation.map(s =>
  s.map(i => i.product_ids).flat(),
);

const $featuredFilterIds = combine(
  [$rebateIds, $bestWarrantyIds, $cheapIds, $allWeatherIds, $recommendationIds],
  arrays => flatten(arrays),
);

const $hasFeaturedFiltersProducts = $featuredFilterIds.map(
  ids => !isEmpty(ids),
);

sample({
  clock: setValue,
  target: createEffect(() => window.scrollTo(0, 0)),
});

export const stores = {
  $value,
  $isEmpty,
  $isDeals,
  $isBestWarranty,
  $isCheap,
  $isAllWeather,
  $rebateIds,
  $featuredFilterIds,
  $bestWarrantyIds,
  $cheapIds,
  $allWeatherIds,
  $hasFeaturedFiltersProducts,
  $productsByRecommendation,
  $isRecommended,
};

export const store = combine(stores);

export const actions = {
  setValue,
  clearValue,
  initRebateIds,
  initBestWarrantyIds,
  initCheapIds,
  initAllWeatherIds,
  initProductsByRecommendations,
};
